import {IdentityDto} from '../models/identity-dto.model';

export class IdUtil {
  static getDobAndGenderFromSaId(id: string): IdentityDto {

    // validate SA ID first. must be 13 characters and all numbers.
    if (id && id.length === 13 && isNumber(id)) {
      const year: number = Number(id.substring(0, 2));
      const month: number = Number(id.substring(2, 4));
      const day: number = Number(id.substring(4, 6));

      const genderNumber: number = Number(id.substring(6, 10));
      const gender = genderNumber > 4999 ? 'MALE' : 'FEMALE';

      return {
        dob: new Date(year, month, day),
        gender,
      };
    }

    return {
      dob: undefined,
      gender: undefined,
    };
  }
}

const isNumber = (numStr: string) => !isNaN(parseFloat(numStr)) && !isNaN(+numStr);
