<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <p class="text-secondary disabled">MENU</p>
  </div>

  <app-alert class="w-100" [id]="'organisation-profile-details'"></app-alert>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/dashboard"
        routerLinkActive="active"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/category-management"
        routerLinkActive="active"
      >Category Management</a>
    </li>

    <li [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/facilitator-management"
        routerLinkActive="active"
      >Facilitator Management</a>
    </li>

    <li [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/client-management"
        routerLinkActive="active"
      >Client Management</a>
    </li>

    <!-- <li [ngbNavItem]="5">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/survey-management"
        routerLinkActive="active"
      >Survey Management</a>
    </li> -->

    <li [ngbNavItem]="6">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/reporting"
        routerLinkActive="active"
      >Reporting</a>
    </li>

    <li [ngbNavItem]="7">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/analytics"
        routerLinkActive="active"
      >Analytics</a>
    </li>

    <li [ngbNavItem]="8">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/notification"
        routerLinkActive="active"
      >Notifications</a>
    </li>

    <li [ngbNavItem]="9">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/organisation/profile"
        routerLinkActive="active"
      >Organisation Profile</a>
    </li>

  </ul>
  <div id="bottom" class="mb-5 text-center">
    <img src="{{organisationProfile?.photo?.location}}" alt="Logo" width="100" class="mb-3">
    <h5>{{organisationProfile?.name}}</h5>
  </div>

</div>
