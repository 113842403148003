import {createUser, User} from 'app/modules/authentication/user/user.model';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {FileUploadSpaces} from '../../../../../shared/modules/file-upload/models/file-upload.model';
import {PAT_CONTACT_NO} from '../../../../organisation-registration/store/organisation-profile.model';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserManagementService} from '../../../../../services/user-management/user-management.service';
import {Province} from '../../location-management/store/location.model';


export const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';
export const PAT_OPTIONAL_EMAIL = '^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';
export const PAT_ID = '^$|^[0-9]{13}$';

export enum LanguageType {
  AFRIKAANS = 'AFRIKAANS',
  ENGLISH = 'ENGLISH',
  NDEBELE = 'NDEBELE',
  XHOSA = 'XHOSA',
  ZULU = 'ZULU',
  SESOTHO = 'SESOTHO',
  SEPEDI = 'SEPEDI',
  SETSWANA = 'SETSWANA',
  SISWATI = 'SISWATI',
  TSHIVENDA = 'TSHIVENDA',
  XITSONGA = 'XITSONGA',
}

export enum FacilitatorType {
  INDEPENDENT = 'INDEPENDENT',
  INTERNAL = 'INTERNAL',
}

export interface FacilitatorProfile extends BaseEntity {
  user?: User;

  idNumber?: string;
  status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';
  // province?: ProvinceType;
  provinceEntity?: Province;
  languages?: LanguageType[];
  type?: FacilitatorType;
  surveyTraining?: number[];
  contactNo?: string;
  location?: string;
  latitude?: number;
  longitude?: number;

  photo?: FileUploadSpaces;

  deletedBy?: string;
  deletedOn?: any;
}

export interface FacilitatorProfileInclusiveDto extends FacilitatorProfile {
  organisationAssignments: string[];
}

export function createFacilitatorProfile(params: Partial<FacilitatorProfile>) {
  return Object.assign({}, createBaseEntity(params), {
    user: createUser(params?.user),

    idNumber: params?.idNumber,
    status: params?.status,
    provinceEntity: params?.provinceEntity,
    languages: params?.languages,
    surveyTraining: params?.surveyTraining,
    contactNo: params?.contactNo,
    location: params?.location,
    latitude: params?.latitude,
    longitude: params?.longitude,

    photo: params?.photo,
  }) as FacilitatorProfile;
}

export function createFacilitatorProfileForm(userManagementService: UserManagementService,
                                             formBuilder: FormBuilder,
                                             params?: Partial<FacilitatorProfile>,
                                             existingUsername?: string): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {

    idNumber: [params && params.user && params.user.username || '',
      [
        Validators.required,
        // Validators.pattern(PAT_ID)
      ],
      (control: AbstractControl): Observable<ValidationErrors | null> =>
        uniqueValueCheck$(control, userManagementService, existingUsername)
    ],
    firstName: [params && params.user && params.user.firstName || '', Validators.required],
    lastName: [params && params.user && params.user.lastName || '', Validators.required],
    email: [params && params.user && params.user.email || '', [
      // Validators.required,
      Validators.pattern(PAT_OPTIONAL_EMAIL)
    ]],

    status: [params?.status],
    contactNo: [params?.contactNo, [
      Validators.required,
      // Validators.pattern(PAT_CONTACT_NO)
    ]],

    provinceEntityId: [params?.provinceEntity?.id],
    // country: [params?.provinceEntity?.country],
    languages: [params?.languages],
    surveyTraining: [params?.surveyTraining],
    location: [params?.location],
    latitude: [params?.latitude],
    longitude: [params?.longitude],

    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),

  }));
}

function uniqueValueCheck$(control: AbstractControl, userManagementService: UserManagementService, existingUsername: string)
  : Observable<ValidationErrors | null> {
  return userManagementService.checkUniqueUsername(control.value, existingUsername).pipe(map(response => {
    if (response) {
      return null;
    } else {
      return {checkIsUnique: 'This ID is already in use. Please choose another.'};
    }
  }));
}
