<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-text
    [label]="label"
    [labelExtraInfo]="labelExtraInfo"
    [id]="id"
    [formSubmitted]="formSubmitted"
    [isEmail]="isEmail"
    [isContactNo]="isContactNo"
    [stringPrepend]="stringPrepend"
    [stringAppend]="stringAppend"
    [iconClassAppend]="iconClassAppend"
    [urlFragment]="urlFragment"
    [uniqueAdditionalParam]="uniqueAdditionalParam"
    [uniqueRules]="uniqueRules"
    [uniqueId]="uniqueId"
    [uniqueDescription]="uniqueDescription"
    [placeholder]="placeholder"
    [type]="type"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [isUnique]="isUnique"
    [maxLength]="maxLength"
    [minLength]="minLength"
    [markUnTouched]="markUnTouched"
    [theme]="theme"
    [style]="style"
    [class]="class"
    [uniqueError]="uniqueError"
    [clickableIcon]="clickableIcon"
    (onIconClick)="onClickCallback()"
    (childValue)="value"
    [(ngModel)]="value"
  ></app-form-control-text>
</ng-container>

<ng-template #disabledInput>
  <app-input-text-disabled
    [label]="label"
    [theme]="theme"
    [style]="style"
    [isGrey]="isGrey"
    [hasBorder]="hasBorder"
    [value]="showDisabledValue ? value : ''"
  ></app-input-text-disabled>
</ng-template>
