<div>
  <div class="w-100 p-4">

    <app-spinner [loadingObs]="loading">

      <!--  <div class="row m-1 mt-3 border">-->
      <!--    <div class="col-12 p-3 pt-0">-->

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h5 class="my-3 d-inline-block">
              User Profile - {{user?.firstName + ' ' + user?.lastName}}
            </h5>
          </div>
        </div>

        <form
          *ngIf="entityForm"
          class="form"
          [formGroup]="entityForm"
          #form="ngForm"
          (ngSubmit)="submit(entityForm)"
        >

          <!--        <div class="row">-->

          <!--          <div class="col-4 p-0">-->
          <!--            <div class="row m-1 border">-->
          <!--              <div class="col-12 p-1 pl-2">-->

          <!--                <div class="card-body p-0">-->
          <!--                  <div class="row">-->
          <!--                    <div class="col-12">-->
          <!--                      <h5 class="my-3">-->
          <!--                        {{user?.firstName}} {{user?.lastName}} [{{user?.email}}]-->
          <!--                      </h5>-->
          <!--                      &lt;!&ndash;                <p *ngIf="user">User since: {{user.createdOn | date : 'dd/MM/yyyy'}}</p>&ndash;&gt;-->
          <!--                    </div>-->
          <!--                  </div>-->

          <!--                  <div class="row">-->
          <!--                    <div class="col-12">-->
          <!--                      <p>Status: {{user?.userStatus | singleChoiceInputType : statusTypes}}</p>-->
          <!--                    </div>-->
          <!--                    <div class="col-12">-->
          <!--                      <div class="btn-group" role="group" aria-label="Status">-->
          <!--                        <button-->
          <!--                          type="button"-->
          <!--                          [class.btn-success]="status == 'ACTIVE'"-->
          <!--                          class="btn btn-outline-success"-->
          <!--                          (click)="updateStatus('ACTIVE')">-->
          <!--                          Activate-->
          <!--                        </button>-->
          <!--                        <button-->
          <!--                          type="button"-->
          <!--                          [class.btn-warning]="status == 'INACTIVE'"-->
          <!--                          class="btn btn-outline-warning text-dark"-->
          <!--                          (click)="updateStatus('INACTIVE')">-->
          <!--                          Deactivate-->
          <!--                        </button>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->


          <!--          <div class="col-5">-->

          <!--            <div class="row m-1 border">-->
          <!--              <div class="col-12 p-3">-->


          <div class="card-body">
            <div class="row my-3">
              <div class="col-12">
                <h5 class="my-3 d-inline-block">User Information</h5>
                <button class="btn btn-success float-right" type="submit">
                  Save
                </button>
                <a class="btn btn-light border-2 float-right mr-2"
                   type="button"
                   routerLink="/organisation/profile/user-management/view/{{username}}">
                  Close
                </a>
              </div>
            </div>

            <app-alert class="w-100" [id]="'admin-profile'"></app-alert>

            <div class="row">
              <div class="col-5">
                <app-input-text
                  [label]="'First name*'"
                  [id]="'firstName'"
                  [isRequired]="true"
                  [style]="'default'"
                  [theme]="'light'"
                  [formSubmitted]="form.submitted"
                  formControlName="firstName"
                ></app-input-text>

                <app-input-text
                  [label]="'Last name*'"
                  [id]="'lastName'"
                  [isRequired]="true"
                  [style]="'default'"
                  [theme]="'light'"
                  [formSubmitted]="form.submitted"
                  formControlName="lastName"
                ></app-input-text>

                <app-input-text
                  [label]="'Username/Email Address*'"
                  [labelExtraInfo]="'(This email address is used to log into the system. The user will need to use the updated email address to login)'"
                  [id]="'email'"
                  [isEmail]="true"
                  [isRequired]="true"
                  [style]="'default'"
                  [theme]="'light'"
                  [formSubmitted]="form.submitted"
                  formControlName="email"
                ></app-input-text>

                <div class="default-input-group position-relative">
                  <div class="input-label-text">
                    <label
                      class="mb-2 default-input-label text-dark bg-white"
                      for="contactNo">Contact number*
                    </label>
                  </div>
                  <div class="default-input border-dark"
                       [class.border-danger]="(form.form.controls['contactNo'].touched || form.submitted) && form.form.controls['contactNo'].errors">
                    <ngx-intl-tel-input
                      id="contactNo"
                      name="'contactNo'"
                      [cssClass]="'input-inner text-dark border-dark'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.SouthAfrica"
                      [maxLength]="'15'"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.International"
                      formControlName="contactNo"
                    ></ngx-intl-tel-input>
                  </div>
                  <div class="validation-notices">
                    <div
                      class="null-or-length-notice"
                      *ngIf="form.form.controls['contactNo'].invalid && (form.form.controls['contactNo'].touched || form.submitted)"
                    >
                      <p *ngIf="form.form.controls['contactNo'].errors['required']"
                         class="font-size-sm font-weight-bold text-danger mt-1">
                        This field is required!
                      </p>
                      <p *ngIf="!form.form.controls['contactNo'].errors['required']"
                         class="font-size-sm font-weight-bold text-danger mt-1">
                        Invalid contact number!
                      </p>
                    </div>
                  </div>
                </div>


                <div class="default-input-group position-relative">
                  <div class="input-label-text">
                    <label
                      class="mb-2 default-input-label text-dark bg-white"
                      for="addressSearch">Street
                    </label>
                  </div>
                  <div class="default-input border-dark">
                    <input
                      id="addressSearch"
                      ngx-google-places-autocomplete
                      [options]="placesOptions"
                      class="input-inner text-dark border-dark"
                      (onAddressChange)="handleAddressChange($event)"
                      formControlName="streetAddress"
                    />
                  </div>
                  <div class="validation-notices"></div>
                </div>

                <app-input-text
                  [label]="'City'"
                  [id]="'city'"
                  [style]="'default'"
                  [theme]="'light'"
                  [showOptional]="false"
                  [formSubmitted]="form.submitted"
                  formControlName="city"
                ></app-input-text>

                <app-input-text
                  [label]="'Postal/Zip code'"
                  [id]="'postalCode'"
                  [showOptional]="false"
                  [style]="'default'"
                  [theme]="'light'"
                  [formSubmitted]="form.submitted"
                  formControlName="postalCode"
                ></app-input-text>

                <app-input-select-single
                  *ngIf="provinceTypes?.length"
                  [label]="'Province'"
                  [id]="'province'"
                  [style]="'default'"
                  [theme]="'light'"
                  [items]="provinceTypes"
                  [showOptional]="false"
                  [formSubmitted]="form.submitted"
                  formControlName="provinceEntityId"
                  (selectedItem)="setCountry($event)"
                ></app-input-select-single>

                <div class="default-input-group position-relative">
                  <div class="input-label-text">
                    <label
                      class="mb-2 default-input-label text-dark bg-white"
                      for="country">Country
                    </label>
                  </div>
                  <div class="default-input border-dark">
                    <input
                      id="country"
                      class="input-inner text-dark border-dark"
                      [disabled]="true"
                      [ngModel]="selectedCountry"
                      [ngModelOptions]="{standalone: true}"
                    />
                  </div>
                  <div class="validation-notices"></div>
                </div>

                <label class="default-input-label text-dark bg-white">GPS Coordinates</label>

                <div class="ml-5">
                  <div class="default-input-group position-relative">
                    <div class="input-label-text">
                      <label class="mb-2 default-input-label text-dark bg-white" for="latitude">
                        N
                      </label>
                    </div>
                    <div
                      class="default-input flex-between"
                      [class.border-danger]="(entityForm.get('latitude').touched || form.submitted) && entityForm.get('latitude').invalid"
                      [ngClass]="{ 'is-invalid': (entityForm.get('latitude').touched || form.submitted) &&entityForm.get('latitude').invalid}"
                    >
                      <input id="latitude"
                             class="input-inner"
                             type="number"
                             name="latitude"
                             formControlName="latitude"
                      >
                    </div>

                    <!--                      <div class="validation-notices">-->
                    <!--                        <div class="null-or-length-notice" *ngIf="(entityForm.get('latitude').touched || form.submitted) && entityForm.get('latitude').hasError('required')">-->
                    <!--                          <p class="font-size-sm font-weight-bold text-danger mt-1">-->
                    <!--                            This field is required!-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->

                  </div>

                  <div class="default-input-group position-relative">
                    <div class="input-label-text">
                      <label class="mb-2 default-input-label text-dark bg-white" for="longitude">
                        W
                      </label>
                    </div>
                    <div
                      class="default-input flex-between"
                      [class.border-danger]="(entityForm.get('longitude').touched || form.submitted) && entityForm.get('longitude').invalid"
                      [ngClass]="{ 'is-invalid': (entityForm.get('longitude').touched || form.submitted) &&entityForm.get('longitude').invalid}"
                    >
                      <input id="longitude"
                             class="input-inner"
                             type="number"
                             name="longitude"
                             formControlName="longitude"
                      >
                    </div>

                    <!--                      <div class="validation-notices">-->
                    <!--                        <div class="null-or-length-notice" *ngIf="(entityForm.get('longitude').touched || form.submitted) && entityForm.get('longitude').hasError('required')">-->
                    <!--                          <p class="font-size-sm font-weight-bold text-danger mt-1">-->
                    <!--                            This field is required!-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                  </div>
                </div>


              </div>

              <div class="offset-1 col-5">

                <div>
                  <img class="profile-image profile-logo-height mb-3"
                       src="{{entityForm?.value?.photo?.location || 'assets/images/common/placeholder.jpg'}}"
                       alt="logo">
                </div>
                <app-image-upload
                  [label]="entityForm?.value?.photo?.location ? 'Replace' : 'Upload'"
                  (imageUploaded)="addLogo($event)"
                ></app-image-upload>
                <a
                  *ngIf="entityForm?.value?.photo?.location"
                  href="javascript:void(0)"
                  class="text-danger fa fa-trash fa-1x mt-1 ml-2"
                  (click)="removePhoto()"
                ></a>
                <div>
                  <p class="font-size-sm font-weight-bold text-success ml-0 mb-2">
                    NB: Maximum file size is 2MB
                  </p>
                </div>

                <!--            <div class="validation-notices">-->
                <!--              <div class="null-or-length-notice" *ngIf="entityForm.get('photo').invalid && form.submitted">-->
                <!--                <p class="font-size-sm font-weight-bold text-danger">-->
                <!--                  This field is required!-->
                <!--                </p>-->
                <!--              </div>-->
                <!--            </div>-->


<!--                <div *ngIf="entityForm?.controls?.roles?.value?.length" class="mt-2">-->
<!--                  <app-input-select-multi-->
<!--                    [label]="'Roles'"-->
<!--                    [id]="'roles'"-->
<!--                    [style]="'bordered'"-->
<!--                    [theme]="'light'"-->
<!--                    [showOptional]="false"-->
<!--                    [isReadOnly]="true"-->
<!--                    [formSubmitted]="form.submitted"-->
<!--                    [items]="roleTypes"-->
<!--                    formControlName="roles"-->
<!--                  ></app-input-select-multi>-->
<!--                </div>-->
                <div class="row mb-2">
                  <div class="col-12 w-100">
                    <p class="mt-3"><strong>Roles </strong></p>
                  </div>
                  <div class="col-12 border ml-3 mt-2 p-2 w-100 input-field">
                    <ul class="pt-2">
                      <ng-container *ngFor="let role of user?.authorities">
                        <li>{{role.name}}</li>
                      </ng-container>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </form>
      </div>
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </app-spinner>
  </div>
</div>
