<div [class]="style + '-input-group'">
  <label
    *ngIf="showLabel"
    class="mb-2"
    [class]="style + '-input-label'"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    [for]="id"
  >
    {{label}}
  </label>
  <div
    [class]="style + '-input flex-between'"
    [class.border-danger]="hasError === 'null' || hasUniqueError"
    [class.border-warning]="hasError === 'length-over' || hasError === 'length-under'"
  >
    <p
      *ngIf="stringPrepend"
      [class]="'input-prepend font-weight-normal ' + style + '-prepend'"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >{{stringPrepend}}</p>
    <input
      #ngModel=ngModel
      class="input-inner"
      [class.input-icon]="!!stringPrepend"
      [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
      [textMask]="{mask: mask}"
      [required]="isRequired"
      [id]="id"
      [name]="id"
      [placeholder]="placeholder"
      [attr.aria-label]="label"
      (blur)="validate()"
      (focus)="select($event)"
      [(ngModel)]="value"
      type="text"
      autocomplete="off"
    >
    <p
      *ngIf="stringAppend"
      [class]="'input-string-append font-weight-normal ' + style + '-append'"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >{{stringAppend}}</p>
  </div>

  <div class="small-spinner">
    <app-spinner [sizeInRem]="2" [loadingBool]="isLoading"></app-spinner>
  </div>

  <ng-container *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <small>This field is optional</small>
      </div>
    </ng-container>

    <div class="validation-notices">

      <div class="null-or-length-notice" *ngIf="hasError === 'null' || hasError === 'length-over' || hasError === 'length-under'">
        <p class="font-size-sm font-weight-bold text-danger" [hidden]="hasError !== 'null'">This field is required!</p>
        <p class="font-size-sm font-weight-bold text-warning" [hidden]="hasError !== 'length-under'">
          Minimum value allowed is <span class="text-warning underline">{{minLength}}</span>
        </p>
        <p class="font-size-sm font-weight-bold text-warning" [hidden]="hasError !== 'length-over'">
          Maximum value allowed is <span class="text-warning underline">{{maxLength}}</span>
        </p>
      </div>

      <div class="unique-notice" *ngIf="hasUniqueError">
        <p class="font-size-sm font-weight-bold text-danger">{{uniqueError}}</p>
      </div>
    </div>
  </ng-container>
</div>
