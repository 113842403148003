export function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    const aValue = a.toLocaleLowerCase();
    const bValue = b.toLocaleLowerCase();
    // return (aValue < bValue ? -1 : 1) * (isAsc ? 1 : -1);

    let returnVal = 0;
    if (aValue && bValue) {
      if (aValue > bValue) {
        returnVal = 1;
      } else if (aValue < bValue) {
        returnVal = -1;
      }
    } else if (aValue) {
      returnVal = 1;
    } else if (bValue) {
      returnVal = -1;
    }
    return returnVal * (isAsc ? 1 : -1);
  }

  // return (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  let retVal = 0;
  if (a && b) {
    if (a > b) {
      retVal = 1;
    } else if (a < b) {
      retVal = -1;
    }
  } else if (a) {
    retVal = 1;
  } else if (b) {
    retVal = -1;
  }
  return retVal * (isAsc ? 1 : -1);
}

// private compareItems(itemA: any, itemB: any): number {
//       let retVal: number = 0;
//       if (itemA && itemB) {
//         if (itemA > itemB) retVal = 1;
//         else if (itemA < itemB) retVal = -1;
//       }
//       else if (itemA) retVal = 1;
//       else if (itemB) retVal = -1;
//       return retVal;
//     }
