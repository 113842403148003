import {Pipe, PipeTransform} from '@angular/core';
import {ChoiceInputItem} from '../../../form-elements/models/form-elements.model';

@Pipe({
  name: 'multipleChoiceInputType'
})
export class MultipleChoiceInputTypePipe implements PipeTransform {

  // use class="break-text" on the element using this pipe to break the text
  transform(values: any[], choices: ChoiceInputItem[]): string {
    const stringList = values.map(val => {
      return this.getTypeByValue(val, choices);
    });

    return stringList.join(' \n');
  }

  getTypeByValue(code: any, choiceInputItem: ChoiceInputItem[]): string {
    const found = choiceInputItem.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return code;
  }

}
