import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {ChoiceInputItem} from '../modules/form-elements/models/form-elements.model';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {CoordinateDto} from '../models/dashboard.model';

export interface AddressDto {
  streetAddress?: string;
  city?: string;
  provinceValue?: any;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
}

@Injectable({providedIn: 'root'})
export class GooglePlacesUtil {

  constructor(private toastr: ToastrService) {
  }

  getLocation(setLocation?): CoordinateDto {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        setLocation(longitude, latitude);
        // return {
        //   latitude,
        //   longitude
        // };
      }, (positionError) => {
        this.toastr.warning(
          'No support for geolocation. Address lookup will not be found closest to your location.',
          'Unknown Location',
          {progressBar: true, timeOut: 10000}
        );
        return;
      });
    } else {
      this.toastr.warning(
        'No support for geolocation. Address lookup will not be found closest to your location.',
        'Unknown Location',
        {progressBar: true, timeOut: 10000}
      );
      return;
    }
  }

  handleAddressChange(address: Address, provinceTypes: ChoiceInputItem[]): AddressDto {
    // console.log('address: ', address);

    const dto: AddressDto = {};

    const streetAddress: any[] = [];
    let city = '';
    let provinceValue;
    let postalCode = '';
    let latitude: number;
    let longitude: number;

    if (address && address.address_components) {
      address.address_components.forEach(add => {
        if (add.types.includes('street_number')) {
          streetAddress.push(add.long_name);
        }

        // street name
        if (add.types.includes('route')) {
          streetAddress.push(add.long_name);
        }

        // suburb
        if (add.types.includes('sublocality_level_1')) {
          streetAddress.push(add.long_name);
        }

        // unknown area
        if (add.types.includes('administrative_area_level_3')) {
          streetAddress.push(add.long_name);
        }

        // unknown area
        if (add.types.includes('administrative_area_level_2')) {
          streetAddress.push(add.long_name);
        }

        // city
        if (add.types.includes('locality')) {
          city = add.long_name;
        }

        // province
        if (add.types.includes('administrative_area_level_1')) {
          const province: ChoiceInputItem = provinceTypes.find(
            item => item.label === add.long_name);
          if (province) {
            provinceValue = province.value;
          } else {
            this.toastr.warning(
              `Province[${add.long_name}] is not available for selection. Please contact the hub to add it.`,
              'Unknown Location',
              {progressBar: true, timeOut: 10000}
              );
          }
        }

        // postal code
        if (add.types.includes('postal_code')) {
          postalCode = add.long_name;
        }
      });

      latitude = address.geometry.location.lat();
      longitude = address.geometry.location.lng();
    }

    dto.streetAddress = streetAddress.join(', ');
    dto.city = city;
    dto.provinceValue = provinceValue;
    dto.postalCode = postalCode;
    dto.latitude = latitude;
    dto.longitude = longitude;

    return dto;
  }
}
