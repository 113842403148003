import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {NotificationDashboardDto, NotificationModel} from './notification.model';
import {tap} from 'rxjs/operators';
import {SessionQuery} from 'app/modules/authentication/session/session.query';

@Injectable({providedIn: 'root'})
export class NotificationService {
  private unReadNotifications = new BehaviorSubject<NotificationModel[]>([]);
  private dashboardNotifications = new BehaviorSubject<NotificationDashboardDto>(undefined);

  constructor(private http: HttpClient,
              public sessionQuery: SessionQuery) {
  }

  // ---- UnRead Notifications ------------------------------
  getUnReadNotifications(): Observable<NotificationModel[]> {
    return this.unReadNotifications.asObservable();
  }

  private setUnReadNotifications(list: NotificationModel[]): void {
    this.unReadNotifications.next(list);
  }

  refreshUnReadNotifications() {
    const username = this.sessionQuery.getUser().username;
    const url = `${environment.apiUrl}/notification?username=${username}&unread=true`;
    let timer = null;
    this.http.get<NotificationModel[]>(url).subscribe(list => {
      this.setUnReadNotifications(list);
    });

    timer = setTimeout(() => {
      const isLogin = this.sessionQuery.isLoggedIn;
      if (isLogin) {
        this.http.get<NotificationModel[]>(url).subscribe(list => {
          this.setUnReadNotifications(list);
        });
      } else {
        timer = null;
      }
    }, 60000);

    return timer;
  }


  // ---- Dashboard Notifications ------------------------------
  getDashboardNotifications(): Observable<NotificationDashboardDto> {
    return this.dashboardNotifications.asObservable();
  }

  private setDashboardNotifications(dto: NotificationDashboardDto): void {
    this.dashboardNotifications.next(dto);
  }

  refreshDashboardNotifications() {
    const username = this.sessionQuery.getUser().username;
    const url = `${environment.apiUrl}/notification/dashboard?username=${username}`;
    let timer = null;
    this.http.get<NotificationDashboardDto>(url).subscribe(dto => {
      this.setDashboardNotifications(dto);
    });

    timer = setTimeout(() => {
      const isLogin = this.sessionQuery.isLoggedIn;
      if (isLogin) {
        this.http.get<NotificationDashboardDto>(url).subscribe(dto => {
          this.setDashboardNotifications(dto);
        });
      } else {
        timer = null;
      }
    }, 60000);

    return timer;
  }


  // ---- Api Calls ------------------------------
  getAll(): Observable<NotificationModel[]> {
    const username = this.sessionQuery.getUser().username;
    const url = `${environment.apiUrl}/notification?username=${username}`;
    return this.http.get<NotificationModel[]>(url);
  }

  getById(notificationId: number): Observable<NotificationModel> {
    const url = `${environment.apiUrl}/notification/${notificationId}`;
    return this.http.get<NotificationModel>(url);
  }

  delete(id: number): Observable<void> {
    const url = `${environment.apiUrl}/notification/${id}`;
    return this.http.delete<void>(url).pipe(tap(() => {
      this.refreshUnReadNotifications();
      this.refreshDashboardNotifications();
    }));
  }

  markNotificationsAsRead(ids: number[]): Observable<void> {
    const url = `${environment.apiUrl}/notification/read?ids=${ids}`;
    return this.http.put<void>(url, undefined).pipe(tap(() => {
      this.refreshUnReadNotifications();
      this.refreshDashboardNotifications();
    }));
  }

  grantAccess(householdId, organisationId) {
    const url = `${environment.apiUrl}/household-organisation/attach?householdId=${householdId}&organisationId=${organisationId}`;
    return this.http.put<void>(url, undefined);
  }
}
