import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {SessionQuery} from 'app/modules/authentication/session/session.query';
import {SessionStore} from 'app/modules/authentication/session/session.store';
import {UserService} from 'app/modules/authentication/user/user.service';
import {LoadingService} from 'app/services/loading/loading.service';
import {AlertService} from 'app/shared/modules/alert/services/alert.service';
import {ChoiceInputItem} from 'app/shared/modules/form-elements/models/form-elements.model';
import {forkJoin, Observable} from 'rxjs';
import {createOrgProfileForm, UpdateAdminUser, User} from '../../../../../authentication/user/user.model';
import {SessionService} from '../../../../../authentication/session/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Province} from '../../../../../admin/components/location-management/store/location.model';
import {LocationService} from '../../../../../admin/components/location-management/store/location.service';
import {tap} from 'rxjs/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {FileUploadSpaces} from '../../../../../../shared/modules/file-upload/models/file-upload.model';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {GooglePlacesUtil} from '../../../../../../shared/utils/google-places-util';
import {Options} from 'ngx-google-places-autocomplete/objects/options/options';

export const statusTypes: ChoiceInputItem[] = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Pending Approval',
    value: 'PENDING_APPROVAL',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Invitation Sent',
    value: 'INVITED',
  },
  {
    label: 'Unspecified',
    value: 'UNSPECIFIED',
  },
];

export const adminRoleTypes: ChoiceInputItem[] = [
  {
    label: 'Super Hub',
    value: 'ROLE_SUPER_HUB',
  },
  {
    label: 'Hub',
    value: 'ROLE_HUB',
  },
  {
    label: 'Legacy',
    value: 'ROLE_LEGACY',
  },
];


export const roleTypes: ChoiceInputItem[] = [
  {
    label: 'Super Hub',
    value: 'ROLE_SUPER_HUB',
  },
  {
    label: 'Hub',
    value: 'ROLE_HUB',
  },
  {
    label: 'Legacy',
    value: 'ROLE_LEGACY',
  },
  {
    label: 'Organisation',
    value: 'ROLE_ORGANISATION',
  },

  {
    label: 'Facilitator',
    value: 'ROLE_FACILITATOR',
  },
  {
    label: 'Internal Facilitator',
    value: 'ROLE_INTERNAL_FACILITATOR',
  },
  {
    label: 'Independent Facilitator',
    value: 'ROLE_INDEPENDENT_FACILITATOR',
  },
];

@Component({
  selector: 'app-org-admin-profile',
  templateUrl: './org-admin-profile.component.html',
  styleUrls: ['./org-admin-profile.component.scss'],
})
export class OrgAdminProfileComponent implements OnInit {
  loading: Observable<boolean>;
  username: string;
  entityForm: FormGroup;
  isLoggedInUser: boolean;

  user: User;
  statusTypes: ChoiceInputItem[] = statusTypes;
  status: string;

  selectedCountry: string;
  provinceList: Province[] = [];
  provinceTypes: ChoiceInputItem[] = [];

  placesOptions: Options = {
    fields: ['address_components', 'geometry'],
    componentRestrictions: {
      // @ts-ignore
      country: []
    }
  };

  roleTypes: ChoiceInputItem[] = roleTypes;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SouthAfrica];

  constructor(private sessionQuery: SessionQuery,
              private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private userService: UserService,
              private locationService: LocationService,
              private googlePlacesUtil: GooglePlacesUtil,
              private alertService: AlertService,
              private sessionStore: SessionStore,
              private sessionService: SessionService,
              public route: ActivatedRoute,
              private router: Router,
              private ref: ChangeDetectorRef) {
    const lat = sessionQuery.getLatitude();
    const long = sessionQuery.getLongitude();

    if (lat && long) {
      this.placesOptions.bounds = {
        // @ts-ignore
        north: long + 0.1,
        south: long - 0.1,
        east: lat + 0.1,
        west: lat - 0.1,
      };
    }
  }

  ngOnInit(): void {
    this.username = this.route.snapshot.params.username;

    const loggedInUser = this.sessionQuery.getUser();
    this.isLoggedInUser = this.username === loggedInUser.username;

    this.fetch();
  }

  fetch() {
    const actions = [this.fetchUser(), this.fetchProvinces()];
    this.loadingService.setIsLoading(true);
    forkJoin(actions).subscribe(results => {
      this.loadingService.setIsLoading(false);
    }, () => {
      this.loadingService.setIsLoading(false);
    }, () => {
        this.buildEntityForm();
    });
  }

  fetchUser() {
    return this.userService.getOrganisationUser(this.username).pipe(tap(
      (user) => {
        this.user = user;
        this.status = user.userStatus;
        // if (this.isLoggedInUser) {
        //   this.sessionStore.updateUser(user);
        // }
        // this.loadingService.setIsLoading(false);
      },
      () => {
        this.alertService.setAlert('Failed to fetch user.', 'error', 'admin-profile');
        // this.loadingService.setIsLoading(false);
      }
    ));
  }

  private fetchProvinces() {
    return this.locationService.getAllProvinces().pipe(tap(list => {
      this.provinceList = list;
      this.provinceTypes = list.map(x => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      const countryCodes = list.reduce(
        (acc, x) => {
          if (!acc.includes(x.country.code.toLowerCase())) {
            acc.push(x.country.code.toLowerCase());
          }
          return acc;
        }, []);

      // @ts-ignore
      this.placesOptions.componentRestrictions.country = countryCodes;
      this.preferredCountries = countryCodes;
      this.ref.detectChanges();
    }));
  }

  private buildEntityForm() {
    if (this.entityForm) {
      this.entityForm.patchValue(this.user);
    } else {
      this.user.username = this.user.email;
      this.entityForm = createOrgProfileForm(this.formBuilder, this.user);
    }
  }

  updateStatus(status: string) {
    this.loadingService.setIsLoading(true);
    this.userService.updateStatus(this.username, status).subscribe(() => {
      this.fetchUser().subscribe(() => {
        this.loadingService.setIsLoading(false);
      }, () => {
        this.loadingService.setIsLoading(false);
      });
    });
  }

  submit(entityForm) {
    if (entityForm.valid) {
      this.loadingService.setIsLoading(true);
      const contactNo = entityForm.controls.contactNo.value;

      const adminUser: UpdateAdminUser = {
        existingUsername: entityForm.value.username,
        newUsername: entityForm.value.email,
        email: entityForm.value.email,
        firstName: entityForm.value.firstName,
        lastName: entityForm.value.lastName,

        contactNo: contactNo.internationalNumber,
        streetAddress: entityForm.value.streetAddress,
        city: entityForm.value.city,
        provinceEntityId: entityForm.value.provinceEntityId,
        postalCode: entityForm.value.postalCode,
        latitude: entityForm.value.latitude,
        longitude: entityForm.value.longitude,
        photo: entityForm.value.photo,
      };

      this.userService.updateUser(adminUser).subscribe(
        (savedUser) => {
          this.sessionService.refreshUser().subscribe(() => {});
          if (this.isLoggedInUser) {
            if (adminUser.newUsername === adminUser.existingUsername) {
              this.router.navigateByUrl('/organisation/profile/user-management').then(() => {
                this.loadingService.setIsLoading(false);
              });
            } else {
              this.router.navigateByUrl('/authentication/login').then(() => {
                this.sessionService.logout();
              });
            }
            this.loadingService.setIsLoading(false);
          } else {
            this.router.navigateByUrl('/organisation/profile/user-management').then(() => {
              this.loadingService.setIsLoading(false);
            });
          }
        },
        () => {
          this.alertService.setAlert(
            'Failed to update profile.',
            'error',
            'org-user-profile'
          );
        }
      );
    }
  }

  handleAddressChange(address: Address) {
    const addressDto = this.googlePlacesUtil.handleAddressChange(address, this.provinceTypes);

    this.setCountry(addressDto.provinceValue);

    this.entityForm.controls.streetAddress.patchValue(addressDto.streetAddress);
    this.entityForm.controls.city.patchValue(addressDto.city);
    this.entityForm.controls.postalCode.patchValue(addressDto.postalCode);
    this.entityForm.controls.provinceEntityId.patchValue(addressDto.provinceValue);

    this.entityForm.controls.latitude.patchValue(addressDto.latitude);
    this.entityForm.controls.longitude.patchValue(addressDto.longitude);
  }

  setCountry(provinceId: number) {
    const found = this.provinceList.find(x => x.id === provinceId);
    this.selectedCountry = found?.country?.name || undefined;
  }

  addLogo(fileUploadSpaces: FileUploadSpaces) {
    this.entityForm.controls.photo.patchValue(fileUploadSpaces);
  }

  removePhoto() {
    this.entityForm.controls.photo.reset();
    this.entityForm.controls.photo.patchValue(undefined);
  }
}
