import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TreeviewModule} from 'ngx-treeview';
import {TokenInterceptorService} from './services/interceptors/token-interceptor/token-interceptor.service';
import {ErrorInterceptorService} from './services/interceptors/error-interceptor/error-interceptor.service';
import {SharedCommonModule} from './shared/modules/shared-common/shared-common.module';
import {NG_ENTITY_SERVICE_CONFIG} from '@datorama/akita-ng-entity-service';
import {environment} from '../environments/environment';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {CommonModule, DatePipe} from '@angular/common';
import {AuthenticatedContainerComponent} from './common/components/authenticated-container/authenticated-container.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {
  RegistrationProfileComponent
} from './modules/organisation-registration/components/registration-profile/registration-profile.component';
import {FormElementsModule} from './shared/modules/form-elements/form-elements.module';
import {
  RegistrationWelcomeComponent
} from './modules/organisation-registration/components/registration-welcome/registration-welcome.component';
import {
  RegistrationSubmittedComponent
} from './modules/organisation-registration/components/registration-submitted/registration-submitted.component';
import {ToastrModule} from 'ngx-toastr';
import {OrganisationProfileFormModule} from './common/components/organisation-profile-form/organisation-profile-form.module';
import {ErrorComponent} from './common/components/error/error.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {SingleChoiceInputTypePipe} from './shared/modules/shared-common/pipes/choiceInput/single-choice-input-type.pipe';
import {ScorecardPdfForMobileComponent} from './modules/scorecard-pdf-for-mobile/scorecard-pdf-for-mobile.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgChartsModule} from 'ng2-charts';
import {MultipleChoiceInputTypePipe} from './shared/modules/shared-common/pipes/choiceInput/multiple-choice-input-type.pipe';
import {GoogleChartsModule} from 'angular-google-charts';
// import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
// import {ReplaySubject} from 'rxjs';

// export const googleChartsConfigSubject = new ReplaySubject<GoogleChartsConfig>(1);

@NgModule({
  declarations: [
    AppComponent,
    AuthenticatedContainerComponent,
    RegistrationProfileComponent,
    RegistrationWelcomeComponent,
    RegistrationSubmittedComponent,
    ScorecardPdfForMobileComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedCommonModule,
    NgbModule,
    TreeviewModule.forRoot(),
    HttpClientModule,
    environment.production ? [] : [AkitaNgDevtools.forRoot(), AkitaNgRouterStoreModule],
    LeafletModule,
    FormElementsModule,
    ToastrModule.forRoot(),
    OrganisationProfileFormModule,
    MatTooltipModule,
    MatSelectModule,
    // NgxIntlTelInputModule,
    NgxMatSelectSearchModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDatepickerModule,
    NgChartsModule,
    GoogleChartsModule.forRoot({mapsApiKey: 'AIzaSyBm2WjOWMFuLDrondXX24alqu3x21sBWoY'}),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBm2WjOWMFuLDrondXX24alqu3x21sBWoY'
    // }),
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {baseUrl: environment.apiUrl}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    // {
    //   provide: GOOGLE_CHARTS_LAZY_CONFIG,
    //   useValue: googleChartsConfigSubject.asObservable()
    // },
    NgbActiveModal,
    DatePipe,
    SingleChoiceInputTypePipe,
    MultipleChoiceInputTypePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
