import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../shared/models/base-entity.model';
import {
  createFileUploadSpaces,
  FileUploadSpaces
} from '../../../../../../shared/modules/file-upload/models/file-upload.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConductedSurveyInfoDto} from 'app/modules/organisation/components/organisation-survey-management/store/conducted-survey.model';
import {PAT_CONTACT_NO} from '../../../../../organisation-registration/store/organisation-profile.model';
import {takeUntil} from 'rxjs/operators';
import {PAT_ID} from '../../../facilitator-management/store/facilitator-profile.model';
import {Province} from '../../../location-management/store/location.model';
import {IdUtil} from '../../../../../../shared/utils/id-util';
import {IdentityDto} from '../../../../../../shared/models/identity-dto.model';


export interface Household extends BaseEntity {
  firstName?: string;
  lastName?: string;
  contactNumber?: string;
  gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED';
  dateOfBirth?: any;
  idType?: string;
  idNumber?: string;
  latitude?: number;
  longitude?: number;

  street?: string;
  city?: string;
  suburb?: string;
  postalCode?: string;
  formattedAddress?: string;
  // province?: string;
  provinceEntity?: Province;

  numberOfPeople?: number;
  numberOfChildren?: number;

  status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';

  photo?: FileUploadSpaces;
  members?: HouseholdMember[];
}

export interface HouseholdOnlyDto extends BaseEntity {
  firstName: string;
  lastName: string;
  contactNumber: string;
  gender: 'MALE' | 'FEMALE' | 'UNSPECIFIED';
  dateOfBirth: any;
  idType: 'SA_ID' | 'PASSPORT' | 'REFUGE_PERMIT' | 'OTHER';
  idNumber: string;
  latitude: number;
  longitude: number;

  street: string;
  city: string;
  suburb: string;
  postalCode: string;
  formattedAddress: string;
  // province: string;
  provinceEntity?: Province;

  numberOfPeople: number;
  numberOfChildren: number;

  status: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';

  photo: FileUploadSpaces;
}

export interface HouseholdInclusiveDto extends Household {
  organisationAffiliations: string[];
  organisationIds: number[];

  categoryList: string[];
  subCategoryList: string[];

  surveys?: ConductedSurveyInfoDto[];

  facilitatorList: string[];
}

export interface HouseholdListDto {
  householdId: any;

  firstName: string;
  lastName: string;
  gender: 'MALE' | 'FEMALE' | 'UNSPECIFIED';
  idType: 'SA_ID' | 'PASSPORT' | 'REFUGE_PERMIT' | 'OTHER';
  idNumber: string;

  status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';

  organisationAffiliations: string[];
  organisationIds: number[];

  categoryList: string[];
  subCategoryList: string[];

  categoryIdList: number[];
  subCategoryIdList: number[];

  latitude: number;
  longitude: number;
  numberOfPeople: number;
  numberOfChildren: number;
  dateOfBirth: any;
  createdBy?: string;
  createdOn?: number;

  street: string;
  city: string;
  suburb: string;
  // postalCode: string;
  // formattedAddress: string;
  // province: ProvinceType;
  // provinceEntity?: Province;
  provinceId?: number;
  provinceName?: string;
  countryId?: number;
  countryName?: string;
}

export interface HouseholdGpsCorrectionDto extends BaseEntity{
  latitude: number;
  longitude: number;
  street: string;
  city: string;
  suburb: string;
  // province: ProvinceType;
  provinceEntityId?: number;
}

export interface HouseholdInclusiveListDto extends HouseholdListDto {
  provinceEntity?: Province;
  facilitatorList: string[];
}

export interface HouseholdLegacyDTO extends Household {
  organisationId: number;
  facilitatorId: number;
  categoryId?: number;
  subCategoryId: number;
}

export interface HouseholdMember extends BaseEntity {
  // household?: Household;
  isChild: boolean;
  firstName: string;
  lastName: string;
  contactNumber: string;
  gender: 'MALE' | 'FEMALE' | 'UNSPECIFIED';
  dateOfBirth: any;
}

function createHouseholdMember(params: Partial<HouseholdMember>): HouseholdMember {
  return Object.assign({}, createBaseEntity(params), {
    // household: params?.household,
    isChild: params?.isChild,
    firstName: params?.firstName,
    lastName: params?.lastName,
    contactNumber: params?.contactNumber,
    gender: params?.gender,
    dateOfBirth: params?.dateOfBirth,
  }) as HouseholdMember;
}

function createHouseholdMemberList(members: HouseholdMember[]): HouseholdMember[] {
  const list: HouseholdMember[] = [];
  if (members) {
    for (const member of members) {
      list.push(createHouseholdMember(member));
    }
  }
  return list;
}

export function createHousehold(params: Partial<Household>): Household {
  return Object.assign({}, createBaseEntity(params), {
    firstName: params?.firstName,
    lastName: params?.lastName,
    contactNumber: params?.contactNumber,
    gender: params?.gender,
    dateOfBirth: params?.dateOfBirth,
    idType: params?.idType,
    idNumber: params?.idNumber,
    latitude: params?.latitude,
    longitude: params?.longitude,

    street: params?.street,
    city: params?.city,
    suburb: params?.suburb,
    postalCode: params?.postalCode,
    formattedAddress: params?.formattedAddress,
    // province: params?.province,
    provinceEntity: params?.provinceEntity,

    numberOfPeople: params?.numberOfPeople,
    numberOfChildren: params?.numberOfChildren,

    status: params?.status,

    photo: createFileUploadSpaces(params?.photo),
    members: createHouseholdMemberList(params?.members),
  }) as Household;
}

export function createHouseholdForm(destroy$, formBuilder: FormBuilder, params?: Partial<Household>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {

    firstName: [params?.firstName, Validators.required],
    lastName: [params?.lastName, Validators.required],
    contactNumber: [params?.contactNumber, [
      Validators.required,
      // Validators.pattern(PAT_CONTACT_NO)
    ]],

    gender: [params?.gender, Validators.required],
    dateOfBirth: [params?.dateOfBirth, Validators.required],
    idType: [params?.idType, Validators.required],
    idNumber: [params?.idNumber, Validators.required],

    latitude: [params?.latitude, Validators.required],
    longitude: [params?.longitude, Validators.required],

    street: [params?.street],
    city: [params?.city],
    suburb: [params?.suburb],
    postalCode: [params?.postalCode],
    formattedAddress: [params?.formattedAddress],
    // province: [params?.province],
    provinceEntity: [params?.provinceEntity],

    numberOfPeople: [params?.numberOfPeople],
    numberOfChildren: [params?.numberOfChildren],

    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),

    // This is not used on the ui form, so we submit existing members to prevent it getting deleted.
    members: [params?.members],

    // used for conducted survey
    status: [params.status],
  }));

  formGroup.get('idType').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(value => {
      if (value === 'SA_ID') {
        formGroup.get('idNumber').setValidators([Validators.required, Validators.pattern(PAT_ID)]);
      } else {
        formGroup.get('idNumber').setValidators(Validators.required);
      }
      formGroup.get('idNumber').updateValueAndValidity();
    });

  formGroup.get('idNumber').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(value => {
      if (formGroup.get('idType').value === 'SA_ID') {
        const idDto: IdentityDto = IdUtil.getDobAndGenderFromSaId(value);
        formGroup.get('dateOfBirth').patchValue(idDto.dob);
        formGroup.get('dateOfBirth').updateValueAndValidity();

        formGroup.get('gender').patchValue(idDto.gender);
        formGroup.get('gender').updateValueAndValidity();
      }
    });

  return formGroup;
}

export function createHouseholdOnlyForm(destroy$, formBuilder: FormBuilder, params?: Partial<HouseholdInclusiveDto>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {

    firstName: [params?.firstName, Validators.required],
    lastName: [params?.lastName, Validators.required],
    contactNumber: [params?.contactNumber, [
      Validators.required,
      // Validators.pattern(PAT_CONTACT_NO)
    ]],

    gender: [params?.gender, Validators.required],
    dateOfBirth: [new Date(params?.dateOfBirth), Validators.required],
    idType: [params?.idType, Validators.required],
    idNumber: [params?.idNumber, Validators.required],

    latitude: [params?.latitude, Validators.required],
    longitude: [params?.longitude, Validators.required],

    street: [params?.street],
    city: [params?.city],
    suburb: [params?.suburb],
    postalCode: [params?.postalCode],
    formattedAddress: [params?.formattedAddress],
    // province: [params?.province],
    provinceEntityId: [params?.provinceEntity?.id],

    numberOfPeople: [params?.numberOfPeople],
    numberOfChildren: [params?.numberOfChildren],
    organisationAffiliations: [params?.organisationAffiliations],
    organisationIds: [params?.organisationIds],

    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),

    // used for conducted survey
    status: [params.status],
  }));

  formGroup.get('idType').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(value => {
      if (value === 'SA_ID') {
        formGroup.get('idNumber').setValidators([Validators.required, Validators.pattern(PAT_ID)]);
      } else {
        formGroup.get('idNumber').setValidators(Validators.required);
      }
      formGroup.get('idNumber').updateValueAndValidity();
    });

  formGroup.get('idNumber').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(value => {
      if (formGroup.get('idType').value === 'SA_ID') {
        const idDto: IdentityDto = IdUtil.getDobAndGenderFromSaId(value);
        formGroup.get('dateOfBirth').patchValue(idDto.dob);
        formGroup.get('dateOfBirth').updateValueAndValidity();

        formGroup.get('gender').patchValue(idDto.gender);
        formGroup.get('gender').updateValueAndValidity();
      }
    });

  return formGroup;
}

export function createHouseholdMemberForm(formBuilder: FormBuilder, params?: Partial<HouseholdMember>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    // household: createHousehold(params?.household),
    isChild: [params?.isChild, Validators.required],
    firstName: [params?.firstName, Validators.required],
    lastName: [params?.lastName, Validators.required],
    contactNumber: [
      params?.contactNumber,
      // Validators.pattern(PAT_CONTACT_NO)
    ],
    gender: [params?.gender, Validators.required],
    dateOfBirth: [params?.dateOfBirth, Validators.required],
  }));
}
