import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../models/base-entity.model';
import {BaseUuidEntity, createBaseUuidEntity, createBaseUuidEntityForm} from '../../../models/base-uuid-entity.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface FileUploadSpaces extends BaseEntity {
  bucket: string;
  firstKey: string;
  location: string;
  secondKey: string;
}

export function createFileUploadSpaces(params: Partial<FileUploadSpaces>) {
  return Object.assign({}, createBaseEntity(params), {
    bucket: params && params.bucket,
    firstKey: params && params.firstKey,
    location: params && params.location,
    secondKey: params && params.secondKey,
  }) as FileUploadSpaces;
}

export function createFileUploadSpacesForm(formBuilder: FormBuilder, params?: Partial<FileUploadSpaces>, required = false): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    bucket: [params && params.bucket, required ? Validators.required : undefined],
    firstKey: [params && params.firstKey, required ? Validators.required : undefined],
    location: [params &&  params.location, required ? Validators.required : undefined],
    secondKey: [params &&  params.secondKey, required ? Validators.required : undefined],
  }));
}


export interface FileUploadUuidSpaces extends BaseUuidEntity {
  bucket: string;
  firstKey: string;
  location: string;
  secondKey: string;
}

export function createFileUploadUuidSpaces(params: Partial<FileUploadUuidSpaces>) {
  return Object.assign({}, createBaseUuidEntity(params), {
    bucket: params && params.bucket,
    firstKey: params && params.firstKey,
    location: params && params.location,
    secondKey: params && params.secondKey,
  }) as FileUploadUuidSpaces;
}

export function createFileUploadUuidSpacesForm(formBuilder: FormBuilder,
                                               params?: Partial<FileUploadUuidSpaces>,
                                               required = false): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    bucket: [params && params.bucket, required ? Validators.required : undefined],
    firstKey: [params && params.firstKey, required ? Validators.required : undefined],
    location: [params &&  params.location, required ? Validators.required : undefined],
    secondKey: [params &&  params.secondKey, required ? Validators.required : undefined],
  }));
}


export interface FileUpload extends BaseEntity {
  value: string;
  meta: FileUploadMetaData;
}

export function createFileUpload(params: Partial<FileUpload>) {
  return Object.assign({}, createBaseEntity(params), {
    value: params && params.value,
    meta: params && params.meta || createFileUploadMetaData({})
  }) as FileUpload;
}

interface FileUploadMetaData extends BaseEntity {
  name: string;
  size: string;
  type: string;
}

export function createFileUploadMetaData(params: Partial<FileUploadMetaData>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params && params.name,
    size: params && params.size,
    type: params && params.type
  }) as FileUploadMetaData;
}
