import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {LoadingService} from '../../../../../services/loading/loading.service';
import {ConductedSurveyService} from '../../../../../modules/organisation/components/organisation-survey-management/store/conducted-survey.service';
import {ConductedSurvey} from '../../../../../modules/organisation/components/organisation-survey-management/store/conducted-survey.model';
import {createConductedSurveyForm} from '../../../../../modules/admin/components/client-management/components/store/conducted-survey-form.model';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {Subject} from 'rxjs';

export const timelineTypes: ChoiceInputItem[] = [
  {
    label: 'Baseline',
    value: 'FIRST',
  },
  {
    label: 'Second',
    value: 'SECOND',
  },
  {
    label: 'Third',
    value: 'THIRD',
  },
  {
    label: 'Fourth',
    value: 'FOURTH',
  },
  {
    label: 'Fifth',
    value: 'FIFTH',
  },
];


@Component({
  selector: 'app-client-management-survey-edit-modal',
  templateUrl: './client-management-survey-edit-modal.component.html',
  styleUrls: ['./client-management-survey-edit-modal.component.scss']
})
export class ClientManagementSurveyEditModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() conductedSurvey: ConductedSurvey;

  entityForm: FormGroup;

  timelineTypes: ChoiceInputItem[] = timelineTypes;

  constructor(public activeModal: NgbActiveModal,
              private alertService: AlertService,
              public loadingService: LoadingService,
              private formBuilder: FormBuilder,
              private conductedSurveyService: ConductedSurveyService) {
  }

  ngOnInit(): void {
    this.entityForm = createConductedSurveyForm(this.destroy$, this.formBuilder, this.conductedSurvey);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  closeModal(reason?: string) {
    this.activeModal.close(reason);
  }

  submit(entityForm: FormGroup) {
    this.loadingService.setIsLoading(true);

    if (entityForm.valid) {
      this.conductedSurveyService.update(entityForm.value.id, entityForm.value).subscribe(response => {
        this.loadingService.setIsLoading(false);
        this.closeModal('saved');
      }, error => {
        this.alertService.setAlert('Failed to update survey submission.', 'error', 'conducted-survey-edit');
      });
    } else {
      this.alertService.setAlert('Please complete the form before submitting', 'error', 'conducted-survey-edit');
    }
  }

  getNumberOfPreviousQuestions(dimIdx: number): number {
    return this.conductedSurvey.dimensions.reduce((total, dimension, currentIdx) => {
      if (currentIdx < dimIdx) {
        total += dimension.questions.length;
      }
      return total;
    }, 0);
  }
}
