<div [class]="style + '-input-group'">
  <label
    *ngIf="showLabel"
    class="mb-2"
    [class]="style + '-input-label'"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    [for]="id"
  >
    {{label}}
  </label>

  <div ngbDropdown class="outline-none" (openChange)="$event === false ? validate() : null">
    <button
      ngbDropdownToggle
      type="button"
      [class.border-danger]="hasError === 'null'"
      [class]="style + '-input outline-none text-left flex-between'"
      [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
      [id]="id"
      [name]="id"
      [attr.aria-label]="label"
      (click)="isTouched = true"
    >
      <span
        class="flex-between select-pill-single whitespace-normal font-weight-normal"
        [ngClass]="placeHolder === 'Select an option...' ? ['text-grey', 'border-none'] : ''"
      >
        {{placeHolder}}
        <button
          *ngIf="placeHolder !== 'Select an option...' && allowDelete"
          class="remove-select-item mt-05"
          [ngClass]="theme === 'light'? ['text-dark', 'remove-item-dark'] : ['text-light', 'remove-item-light']"
          (click)="remove($event)"
        >
          <i class="fa fa-trash text-danger"></i>
        </button>
      </span>
    </button>

    <div ngbDropdownMenu [class.dark]="theme === 'dark'" class="select-restrict-height">
          <!-- TODO: complete the search input -->
<!--      <div class="outline-none flex-center w-100">-->
<!--        <input-->
<!--          #searchInput="ngModel"-->
<!--          *ngIf="hasSearch"-->
<!--          type="text"-->
<!--          id="selectSearch"-->
<!--          autofocus-->
<!--          (focusin)="subscribeToValue(searchInput)"-->
<!--          [(ngModel)]="searchValue"-->
<!--        >-->
<!--      </div>-->
      <ng-container *ngFor="let option of items">
        <button
          ngbDropdownItem
          type="button"
          (click)="selectOption(option)"
          class="outline-none flex-between whitespace-normal"
          [class.active-select-item]="value && value === option.value"
          [class.active-select-item-dark]="value && value === option.value && theme === 'dark'"
          [class.active-select-item-light]="value && value === option.value && theme === 'light'"
        >
          {{option.label}}
          <span *ngIf="value && value === option.value">
            <i class="fa fa-check text-success"></i>
          </span>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="input-validation-notices mt-1" *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <p class="font-size-sm font-weight-bold">This field is optional</p>
      </div>
    </ng-container>
    <ng-container *ngIf="hasError === 'null'">
      <div class="validation-notice null-notice">
        <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
      </div>
    </ng-container>
  </div>
</div>
