<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-select-multi
    [label]="label"
    [labelExtraInfo]="labelExtraInfo"
    [id]="id"
    [items]="items"
    [name]="id"
    [formSubmitted]="formSubmitted"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [theme]="theme"
    [style]="style"
    [(ngModel)]="value"
  ></app-form-control-select-multi>
</ng-container>

<ng-template #disabledInput>
  <app-input-select-disabled
    [label]="label"
    [theme]="theme"
    [style]="style"
    [isGrey]="isGrey"
    [hasBorder]="hasBorder"
    [value]="showDisabledValue ? value : 'Select an option'"
  ></app-input-select-disabled>
</ng-template>
