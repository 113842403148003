<nav class="navbar navbar-expand-lg sticky-top">
  <div class="navbar-translate">
    <button *authenticatedWithRole="['Any']" class="btn btn-link mr-3" (click)="toggleSidebar($event)">
      <span class="fa fa-bars fa-2x text-success"></span>
    </button>

    <div class="navbar-brand">
      <img
        alt="poverty-stoplight"
        src="assets/images/poverty-stoplight/psl-logo-white.jpg"
        height="60"
      >
    </div>

    <h4 class="text-success font-weight-bold">Greenlight Platform</h4>
  </div>

  <div class="nav-container">
    <div class="collapse navbar-collapse" data-nav-image="assets/img/blurred-image-1.jpg" data-color="orange">
      <ul class="navbar-nav ml-auto flex-align-center">


        <ng-container *ngIf="sessionQuery.getUserObservable() | async as user">

          <!--          <ng-container *authenticatedWithRole="['ROLE_HUB']">-->
          <!--            <li class="nav-item">-->
          <!--              <a class="nav-link" routerLink="/admin/dashboard" routerLinkActive="active">-->
          <!--                <i class="fa fa-2x fa-home mr-1"></i>-->
          <!--              </a>-->
          <!--            </li>-->
          <!--          </ng-container>-->
          <!--          <ng-container *authenticatedWithRole="['ROLE_FUNDER']">-->
          <!--            <li class="nav-item" *ngIf="user.funderId && user.isSimSubmitted">-->
          <!--              <a class="nav-link" routerLink="/investor/dashboard" routerLinkActive="active">-->
          <!--                <i class="fa fa-2x fa-home mr-1"></i>-->
          <!--              </a>-->
          <!--            </li>-->
          <!--          </ng-container>-->
          <!--          <ng-container *authenticatedWithRole="['ROLE_APPLICANT']">-->
          <!--            <li class="nav-item"-->
          <!--                *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted && user.serviceMatrixId">-->
          <!--              <a class="nav-link" routerLink="/implementer/dashboard" routerLinkActive="active">-->
          <!--                <i class="fa fa-2x fa-home mr-1"></i>-->
          <!--              </a>-->
          <!--            </li>-->
          <!--          </ng-container>-->

          <!--          <p class="text-light mr-1">|</p>-->

          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link disabled" disabled routerLinkActive="active">-->
          <!--              <p>Directories</p>-->
          <!--            </a>-->
          <!--          </li>-->

          <!--          <p class="text-light mr-1">|</p>-->

          <!--          <li class="nav-item">-->
          <!--            <div class="dropdown btn-group">-->
          <!--              <a ngbDropdown placement="bottom-right" class="nav-item dropdown">-->
          <!--                <div class="nav-link dropdown-toggle cursor-pointer" ngbDropdownToggle>-->
          <!--                  <p>Resources</p>-->
          <!--                </div>-->

          <!--                <div ngbDropdownMenu class="dropdown-menu bg-dark text-light text-center">-->

          <!--                  <a class="dropdown-item cursor-pointer text-light disabled" disabled>-->
          <!--                    <p>Funding</p>-->
          <!--                  </a>-->

          <!--                  <a class="dropdown-item cursor-pointer text-light disabled" disabled>-->
          <!--                    <p>Knowledge Base</p>-->
          <!--                  </a>-->

          <!--                </div>-->

          <!--              </a>-->
          <!--            </div>-->
          <!--          </li>-->

          <!--          <p class="text-light mr-1">|</p>-->

          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link disabled" disabled routerLinkActive="active">-->
          <!--              <p>Events</p>-->
          <!--            </a>-->
          <!--          </li>-->

          <div *ngIf="user.isImpersonating" class="text-danger d-inline-block">
            <h6>Impersonating &nbsp;{{user.firstName + ' ' + user.lastName}} &nbsp;</h6>
          </div>

          <div class="text-danger d-inline-block">
            <a href="#" (click)="logout()" class="text-success mr-2">Logout</a>
          </div>

          <li class="nav-item">
            <div class="dropdown btn-group">
              <a ngbDropdown placement="bottom-left" class="nav-item dropdown">
                <div class="nav-link dropdown-toggle notification-toggle cursor-pointer" ngbDropdownToggle>
                  <i class="fa fa-2x fa-bell text-secondary position-relative"></i>
                  <span *ngIf="notifications.length"
                        class="position-absolute top-3 left-20 badge rounded-pill bg-danger">
                    {{notifications.length}}
                  </span>
                </div>
                <div ngbDropdownMenu class="dropdown-menu text-center w-50">

                  <ng-container class="dropdown-item" *ngIf="notifications.length === 0">
                    <p class="mt-3">No new messages</p>
                  </ng-container>

                  <div class="dropdown-item w-100 text-left" *ngIf="notifications.length > 0">
                    <ng-container *ngFor="let notification of notifications; let ix = index">
                      <ng-container *ngIf="ix <= 4">
                        <p class="m-0"><strong>{{notification.subject | truncate : 10}}</strong></p>
                        <p class="ml-3">{{notification.message | truncate : 17}}</p>
                        <button
                          class="btn btn-sm btn-success float-right view-notification"
                          (click)="viewNotification(notification)"
                        >
                          View
                        </button>
                        <br>
                        <hr>
                      </ng-container>
                    </ng-container>
                  </div>

                  <hr>

                  <button
                      class="btn btn-sm btn-success text-center view-notification"
                      (click)="viewMore()"
                  >
                    View more notifications
                  </button>

                </div>
              </a>
            </div>
          </li>
        </ng-container>

        <!--        <li class="nav-item" *authenticatedWithRole="['Unauthenticated']">-->
        <!--          <a routerLink="/authentication/login">-->
        <!--            <button class="nav-link btn btn-primary btn-outline-secondary">-->
        <!--              Login-->
        <!--            </button>-->
        <!--          </a>-->
        <!--        </li>-->

        <!--        <li class="nav-item" *authenticatedWithRole="['Unauthenticated']">-->
        <!--          <a routerLink="/authentication/register">-->
        <!--            <button class="nav-link btn btn-primary btn-outline-light">-->
        <!--              Register-->
        <!--            </button>-->
        <!--          </a>-->
        <!--        </li>-->

        <li *authenticatedWithRole="['Any']" class="ml-1">
          <app-user-navigation></app-user-navigation>
        </li>
      </ul>
    </div>
  </div>
</nav>
