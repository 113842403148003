<div [class]="style + '-input-group position-relative ' + class">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <label
      *ngIf="showLabel"
      class="mb-2"
      [class]="style + '-input-label'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
      [for]="id">{{label}}
      <span *ngIf="labelExtraInfo">{{labelExtraInfo}}</span>
    </label>
    <div class="unique-description-notice mt-1" *ngIf="uniqueDescription">
      <p class="font-size-sm font-weight-bold text-success">{{uniqueDescription}}</p>
    </div>
  </div>
  <div
    [class]="style + '-input flex-between'"
    [ngClass]="theme === 'light' ? 'border-dark' : 'border-light'"
    [class.border-danger]="hasNullError || hasUniqueError || emailInvalid || contactNoInvalid"
    [class.border-warning]="lengthError"
  >
    <p
      *ngIf="stringPrepend"
      class="input-prepend font-weight-normal"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >{{stringPrepend}}</p>
    <input
      #ngModel=ngModel
      class="input-inner"
      [class.input-icon]="!!stringPrepend"
      [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
      [textMask]="uniqueRules?.length ? {mask: uniqueRules, guide: false} : {mask: false}"
      [type]="type"
      [required]="isRequired"
      [maxLength]="maxLength"
      [id]="id"
      [name]="id"
      [placeholder]="placeholder"
      [attr.aria-label]="label"
      (blur)="validate()"
      [(ngModel)]="value"
      autocomplete="off"
    >
    <p
      *ngIf="stringAppend"
      class="input-string-append font-weight-normal"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >{{stringAppend}}</p>
    <i
      *ngIf="iconClassAppend"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class]="iconClassAppend + ' fa-lg ' + 'input-append'"
      [class.default-no-label]="!showLabel && style === 'default'"
      [class.cursor-pointer]="clickableIcon"
      (click)="onClickCallback()"
    ></i>
  </div>

  <div class="small-spinner">
    <app-spinner [sizeInRem]="2" [loadingBool]="isLoading"></app-spinner>
  </div>

  <ng-container *ngIf="showErrors">
    <div class="validation-notice optional-notice" *ngIf="!isRequired && showOptional">
      <p class="font-size-sm font-weight-bold">This field is optional</p>
    </div>

    <div class="validation-notices">

      <div class="null-or-length-notice" *ngIf="hasNullError || lengthError || emailInvalid || contactNoInvalid || hasUniqueError">
        <p class="font-size-sm font-weight-bold text-danger" [hidden]="!hasNullError">This field is required!</p>
        <p class="font-size-sm font-weight-bold text-danger" [hidden]="!hasUniqueError">This field is unique and this value is already in use! Please try another.</p>
        <p class="font-size-sm font-weight-bold text-danger" [hidden]="!emailInvalid">Email is invalid!</p>
        <p class="font-size-sm font-weight-bold text-danger" [hidden]="!contactNoInvalid">Contact No. is invalid!</p>
        <p class="font-size-sm font-weight-bold text-warning" [hidden]="!lengthError || lengthError === 'min'">
          Max character limit reached
        </p>
        <p class="font-size-sm font-weight-bold text-warning" [hidden]="!lengthError || lengthError === 'max'">
          This field must have at least {{minLength}} characters
        </p>
      </div>

<!--      <div class="unique-notice" *ngIf="hasUniqueError">-->
<!--        <p class="font-size-sm font-weight-bold text-danger">{{uniqueError}}</p>-->
<!--      </div>-->

    </div>
  </ng-container>
</div>
