import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-facilitator-sidebar',
  templateUrl: './facilitator-sidebar.component.html',
  styleUrls: ['./facilitator-sidebar.component.scss']
})
export class FacilitatorSidebarComponent implements OnInit {
  activeId = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

}
