import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './components/navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDateNativeAdapter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticatedWithRoleDirective} from './directives/authenticated-with-role/authenticated-with-role.directive';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {FormatDatePipe} from './pipes/format-date/format-date.pipe';
import {AsFormGroupPipe} from './pipes/as-form-group/as-form-group.pipe';
import {UserNavigationComponent} from './components/user-navigation/user-navigation.component';
import {ProvincePipe} from './pipes/location-codes/province/province.pipe';
import {SubDistrictPipe} from './pipes/location-codes/subdistrict/sub-district.pipe';
import {DistrictPipe} from './pipes/location-codes/district/district.pipe';
import {ChevronComponent} from './components/chevron/chevron.component';
import {CountryPipe} from './pipes/location-codes/country/country.pipe';
import {SingleChoiceInputTypePipe} from './pipes/choiceInput/single-choice-input-type.pipe';
import {MultipleChoiceInputTypePipe} from './pipes/choiceInput/multiple-choice-input-type.pipe';
import {AdminSidebarComponent} from './components/admin-sidebar/admin-sidebar.component';
import {AuthenticatedWithoutRoleDirective} from './directives/authenticated-without-role/authenticated-without-role.directive';
import {TruncatePipe} from './pipes/truncate/truncate.pipe';
import {OrganisationSidebarComponent} from './components/organisation-sidebar/organisation-sidebar.component';
import {FacilitatorSidebarComponent} from './components/facilitator-sidebar/facilitator-sidebar.component';
import {ArrayItemToStringPipe} from './pipes/arrayItemToString/arrayItemToString.pipe';
import {AsFormArrayPipe} from './pipes/as-form-array/as-form-array.pipe';
import {RolePipe} from './pipes/role.pipe';
import {AlertModule} from '../alert/alert.module';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    NavbarComponent,
    AuthenticatedWithRoleDirective,
    AuthenticatedWithoutRoleDirective,
    SpinnerComponent,
    FormatDatePipe,
    ArrayItemToStringPipe,
    AsFormGroupPipe,
    AsFormArrayPipe,
    UserNavigationComponent,
    SingleChoiceInputTypePipe,
    MultipleChoiceInputTypePipe,
    CountryPipe,
    ProvincePipe,
    DistrictPipe,
    SubDistrictPipe,
    RolePipe,
    ChevronComponent,
    AdminSidebarComponent,
    TruncatePipe,
    OrganisationSidebarComponent,
    FacilitatorSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbCollapseModule,
    NgbAccordionModule,
    AlertModule,
    NgxIntlTelInputModule
  ],
  exports: [
    NavbarComponent,
    AuthenticatedWithRoleDirective,
    AuthenticatedWithoutRoleDirective,
    SpinnerComponent,
    FormatDatePipe,
    AsFormGroupPipe,
    AsFormArrayPipe,
    ArrayItemToStringPipe,
    SingleChoiceInputTypePipe,
    MultipleChoiceInputTypePipe,
    CountryPipe,
    ProvincePipe,
    DistrictPipe,
    SubDistrictPipe,
    RolePipe,
    ChevronComponent,
    AdminSidebarComponent,
    OrganisationSidebarComponent,
    FacilitatorSidebarComponent,
    TruncatePipe,
    NgxIntlTelInputModule,
  ],
  providers: [
    NgbDateNativeAdapter
  ]
})
export class SharedCommonModule {
}
