<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-number
    [label]="label"
    [id]="id"
    [formSubmitted]="formSubmitted"
    [maxLength]="maxLength"
    [minLength]="minLength"
    [stringPrepend]="stringPrepend"
    [stringAppend]="stringAppend"
    [placeholder]="placeholder"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [theme]="theme"
    [style]="style"
    [isUnique]="isUnique"
    [uniqueId]="uniqueId"
    [urlFragment]="urlFragment"
    [uniqueAdditionalParam]="uniqueAdditionalParam"
    [uniqueError]="uniqueError"
    [numberMask]="numberMask"
    [(ngModel)]="value"
  ></app-form-control-number>
</ng-container>

<ng-template #disabledInput>
  <app-input-number-disabled
    [label]="label"
    [theme]="theme"
    [style]="style"
    [isGrey]="isGrey"
    [hasBorder]="hasBorder"
    [showLabel]="showLabel"
    [stringPrepend]="stringPrepend"
    [stringAppend]="stringAppend"
    [value]="showDisabledValue ? value : ''"
    [numberMask]="numberMask"
    [usePipe]="usePipe"
  ></app-input-number-disabled>
</ng-template>
